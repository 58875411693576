import { AuthContext } from 'context/authContext';
import React from 'react';
import styled from 'styled-components/macro';
import { useContextHook } from 'use-context-hook';

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 1920px) {
    margin-right: 500px;
  }
  @media (max-width: 1600px) {
    margin-right: 400px;
  }
  @media (max-width: 1280px) {
    margin-right: 300px;
  }
  @media (max-width: 1024px) {
    margin-right: 200px;
  }
  @media (max-width: 768px) {
    margin-right: 100px;
  }
`;

const ToggleButton = styled.button`
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: ${props => (props.active ? 'green' : 'white')};
  color: ${props => (props.active ? 'white' : 'black')};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => (props.active ? 'darkgreen' : '#f0f0f0')};
  }
`;

const ToggleButtons = () => {
  const { activeButtons, setActiveButtons } = useContextHook(AuthContext, {
    activeButtons: 1,
    setActiveButtons: 1,
  });

  const handleToggle = index => {
    setActiveButtons(activeButtons.map((_, i) => (i === index ? !_[index] : false)));
  };

  return (
    <ButtonGroup>
      {['Turno 1', 'Turno 2', 'Turno 3'].map((label, index) => (
        <ToggleButton key={index} active={activeButtons[index]} onClick={() => handleToggle(index)}>
          {label}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default ToggleButtons;
