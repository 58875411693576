/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unreachable */
import Toast from 'components/molecules/Toast';
import { LangConverter, clearCookie, getCookie, setCookie } from 'helpers/common';
import { useCancellablePromise } from 'helpers/promiseHandler';
import { useEffect, useState } from 'react';
import API from 'services/api';
import { createContextHook } from 'use-context-hook';

const context = {};
export const AuthContext = createContextHook(context);
export function AuthContextProvider(props) {
  const [activeButtons, setActiveButtons] = useState([true, false, false]);
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem('_lang') ?? '{ "value": "en", "label": "English" }'),
  );
  const [dashboardInterval, setDashboardInterval] = useState({
    value: 'day',
    label: LangConverter('Daily', lang.value),
  });
  const [customDateRangeForDashboard, setCustomDateRangeForDashboard] = useState([null, null]);
  const [options, setOptions] = useState({
    filterMotive: [],
    filterMachine: [],
    filterTurno: [],
  });
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('admin_token'));
  const [allowedPages, setAllowedPages] = useState(JSON.parse(getCookie('allowed_pages')) ?? []);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [refetch_User, setRefetch_User] = useState(false);
  const reFetch = () => setFetch(!fetch);
  const { cancellablePromise } = useCancellablePromise();

  const onLogout = () => {
    if (getCookie('admin_token')) clearCookie('admin_token');
    setIsLoggedIn(false);
    setUser({});
  };
  const onLogin = ({ email, password, demo_user_mail = '' }) =>
    API.login(email, password, demo_user_mail)
      .then(res => {
        let pages = [...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])];
        pages = pages.filter(p => p !== 'no-permissions');
        setAllowedPages(pages);
        setCookie('allowed_pages', JSON.stringify(pages));
        setIsLoggedIn(() => true);
        setUser(() => ({ ...res.user, role_type: res.role_type, permissions: res.permissions }));
        return true;
      })
      .catch(({ message }) => {
        setIsLoggedIn(false);
        Toast({
          message,
          type: 'error',
        });
      });

  const onResetPassword = (_, callback) =>
    API.resetPassword(_)
      .then(res => {
        callback(res);
        Toast({
          type: 'warning',
          message: 'Check your email for the reset link',
        });
      })
      .catch(({ message }) => {
        Toast({
          message,
          type: 'error',
        });
      });

  useEffect(() => {
    if (isLoggedIn) {
      setLoadingUser(true);
      cancellablePromise(API.getCurrentUser())
        .then(res => {
          let pages = [...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])];
          pages = pages.filter(p => p !== 'no-permissions');
          setAllowedPages(pages);
          setCookie('allowed_pages', JSON.stringify(pages));
          setUser(() => ({ ...res.user, role_type: res.role_type, permissions: res.permissions }));
          setLoadingUser(false);
        })
        .catch(err => {
          setAllowedPages(['no-permissions']);
          setCookie('allowed_pages', JSON.stringify(['no-permissions']));
          setLoadingUser(false);
          Toast({
            type: 'error',
            message: err.message,
          });
        });
    }
  }, [isLoggedIn, refetch_User]);
  const refetchUser = () => setRefetch_User(_ => !_);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const passreset = urlParams.get('passreset');
    if (passreset === 'true') {
      Toast({
        autoClose: 10000,
        type: 'success',
        message: 'Please check your email for new password',
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        activeButtons,
        setActiveButtons,
        setIsLoggedIn,
        onLogout,
        onLogin,
        reFetch,
        refetch: reFetch,
        refetchUser,
        setOptions,
        onResetPassword,
        setDashboardInterval,
        setCustomDateRangeForDashboard,
        setLang: x => {
          localStorage.setItem('_lang', JSON.stringify(x));
          setLang(x);
        },
        lang,
        customDateRangeForDashboard,
        dashboardInterval,
        options: {
          filterMotive: [
            { value: 'all', label: 'All' },
            ...options.filterMotive.filter(({ value: f }) => f !== '' && f !== 'all'),
          ],
          filterMachine: [
            { value: 'all', label: 'All' },
            ...options.filterMachine.filter(({ value: f }) => f !== '' && f !== 'all'),
          ],
          filterTurno: [
            { value: 'all', label: 'All' },
            ...options.filterTurno.filter(({ value: f }) => f !== '' && f !== 'all'),
          ],
        },
        allowedPages,
        fetch,
        isLoggedIn,
        loading_user,
        user,
        refetch_User,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
}
